/* You can add global styles to this file, and also import other style files */

@import "commonstyle";
/* #GOOGLE FONT */
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400italic,700italic,300,400,700");
@import "~@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";
@import "~@ng-select/ng-select/themes/default.theme.css";

body:not(.menu-on-top).desktop-detected {
	min-height: auto !important;
	overflow: hidden;
}

body {
	background: unset;
	// background-color: $header-border;
	background-color: #202124;
}

#content {
	padding: 20px !important;
	// max-height: calc(100vh - 100px);
	// overflow-y: auto;
}

.page-footer {
	/* padding-left: 0px; */
	z-index: 100;
	border-top: none;
	position: fixed;
}

#logo {
	background-repeat: no-repeat;
	background-size: 100%;
	height: 49px;
	width: 135px;
	display: block;
	margin-top: 10px;
	margin-left: 36px;
}

.setnavBackgroundImg {
	// border-radius: 100%;
	// box-shadow: 5px 10px 75px rgba(0, 0, 0, .4);
	position: relative;
	z-index: 2;
	max-width: none;
	width: 90px;
	height: 90px;
	cursor: pointer;
	background-repeat: no-repeat;
	display: flex;
	margin: 0 auto;
	height: 110px;
	width: 110px;
}

.col-xs-10 {
	width: 83.33333333% !important;
}

.col-xs-2 {
	width: 16.66666667% !important;
}

.col-xs-3 {
	width: 25% !important;
}

.col-xs-7 {
	width: 58.33333333% !important;
}

.col-xs-8 {
	width: 66.66666667% !important;
}

.col-xs-6 {
	width: 50% !important;
}

.col-xs-1 {
	width: 8.33333333% !important;
}

.col-xs-4 {
	width: 33.33333333% !important;
}

.col-xs-5 {
	width: 41.66666667% !important;
}

#extr-page {
	#header {
		#logo {
			img {
				width: 200px;
			}

			margin-left: 75px !important;
			margin-top: 10px !important;
		}
	}
}

.notification-body {
	.unread {
		background: $table-bgcolor;
	}

	li {
		span {
			background: $secondaryFocus !important;
		}
	}
}

.li-footer__copy-text {
	color: #8B91A0;
}

// * {
//     padding: 0;
//     margin: 0;
//   }
.loader-outer {
	position: absolute;
	top: 50%;
	left: 50%;
	margin: -25px 0 0 -25px;
}

//   body {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     height: 100vh;
//     width: 100%;
//     // background: #000;
//   }

.loader>span {
	width: 15px;
	height: 15px;
	display: block;
	background: var(--theme-color-1);
	border-radius: 50%;
	position: relative;
	margin: 0 5px;
}

.loader {
	display: flex;
	align-items: center;
	justify-content: center;
}

.dot-1 {
	animation: anim 1s linear 0s infinite;
}

.dot-2 {
	animation: anim 1s linear 0.25s infinite;
}

.dot-3 {
	animation: anim 1s linear 0.50s infinite;
}

.dot-4 {
	animation: anim 1s linear 0.75s infinite;
}

@keyframes anim {
	0% {
		top: 0;
	}

	50% {
		top: 15px;
	}

	100% {
		top: 0;
	}
}

.dataTables_info {
	.select-item {
		padding: 5px;
	}
}

@media (max-width: 1161px) and (min-width: 300px) {
	#extr-page #header #logo img {
		width: 150px;
	}

	#extr-page #header #logo {
		margin-left: 10px !important;
		margin-top: 20px !important;
	}
}

@media only screen and (max-width: 280px) {

	#header>:first-child,
	aside {
		width: 150px;
	}

	#extr-page #header #logo img {
		width: 125px;
	}

	#extr-page #header #logo {
		margin-left: 10px !important;
		margin-top: 20px !important;
	}
}

@media (max-width: 992px) {
	#content {
		max-height: calc(100vh - 130px) !important;
	}
}

@media (max-width: 576px) {
	#content {
		max-height: calc(100vh - 180px) !important;
	}
}


.cdk-overlay-container {
	z-index: 9999 !important;
}

.owl-dt-inline {
	width: 100% !important;
}

.owl-dt-inline-container,
.owl-dt-popup-container {
	width: 100% !important;
}

.owl-dt-container {
	font-size: 18px !important;
}

.owl-dt-inline-container .owl-dt-calendar,
.owl-dt-popup-container .owl-dt-calendar {
	height: 500px !important;
}

.cdk-overlay-container {
	z-index: 9999 !important;
}

.owl-dt-inline {
	width: 100% !important;
}

.owl-dt-inline-container,
.owl-dt-popup-container {
	width: 100% !important;
}

.owl-dt-container {
	font-size: 18px !important;
}

.owl-dt-inline-container .owl-dt-calendar,
.owl-dt-popup-container .owl-dt-calendar {
	height: 500px !important;
}

.owl-dt-calendar-table .owl-dt-calendar-cell-active:focus>.owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected),
.owl-dt-calendar-table :not(.owl-dt-calendar-cell-disabled):hover>.owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected) {
	background-color: #666666 !important;
	color: #fff !important;
}

.owl-dt-calendar-table .owl-dt-calendar-cell-today:not(.owl-dt-calendar-cell-selected) {
	background-color: #202124 !important;
	border: 1px solid #666666 !important;
	color: #fff !important;
}

.includePdf label {
	// margin-bottom: 15px !important;	
}

.custom-radio .custom-control-input:checked~.custom-control-label::after {
	background-image: none !important;
}

.custom-radio .custom-control-label::before {
	border-radius: 50%;
	margin-top: 3px;
}

.checkbox i {
	position: absolute;
	top: 3px;
	left: 0;
	display: block;
	width: 17px;
	height: 17px;
	outline: 0;
	border-width: 1px;
	border-style: solid;
	background: transparent !important;
	border: 2px solid #666666;
	border-radius: 5px;
}


.smart-form .checkbox input+i:after {
	color: $common_base_color;
}

.smart-form .checkbox i {
	border-color: #BDBDBD;
	border: 2px solid #666666;
	border-radius: 5px;
}

.smart-form .checkbox:hover i {
	border-color: #666666;
}

.fuelux .wizard ul li.complete:hover {
	cursor: pointer;
	background: none;
}

.custom-control-input:checked~.custom-control-label::before {
	color: #fff;
	border-color: $common_base_color !important;
	background-color: $common_base_color !important;
}

.fuelux .wizard ul li .chevron {
	position: absolute;
	top: 0;
	right: -14px;
	z-index: 1;
	display: block;
	border: 24px solid transparent;
	border-right: 0;
}
.checkbox i{
	position: absolute;
	top: 3px;
	left: 0;
	display: block;
	width: 17px;
	height: 17px;
	outline: 0;
	border-width: 1px;
	border-style: solid;
	background: transparent !important;
	border: 2px solid #666666;
	border-radius: 5px;
}


.smart-form .checkbox input+i:after {
	color: $common_base_color;
}
.smart-form .checkbox i {
	border-color: #BDBDBD;
	border: 2px solid #666666;
	border-radius: 5px;
}
.smart-form .checkbox:hover i{
	border-color: #666666;
}
/*
---------------------------------------------------------------------------------------
    Default Dark theme style
---------------------------------------------------------------------------------------
*/
.smart-style-1 {
	#logo-group {
		background: unset !important;
	}

	#logo {
		background-image: url(/assets/img/svgImages/logo.svg);
	}

	.btn-header {

		&:first-child {
			a {
				background: unset;
				border: 1px solid $border-color;
			}
		}
	}

	#header {
		background-color: $widget-color;
	}

	aside {
		background-color: #171819;
		border-right: 1px solid #333 !important;
	}

	.page-footer {
		background-color: #303030;
	}

	#ribbon {
		background: $tabsborder-color;
	}

	.ajax-dropdown {
		background-color: $secondaryFocus !important;
	}

	.ajax-notifications {
		background-color: $secondaryFocus !important;
	}

	.header-dropdown-list {
		a {
			&:hover {
				color: $primary-color !important;
			}
		}
	}

	.setnavBackgroundImg {
		background-image: url(/assets/img/svgImages/Group348.svg);
	}

	.ng-select .ng-select-container {
		background: transparent;
		color: #fff;
		font-size: 13px;
		border-radius: 10px !important;
		border: unset !important;
	}

	.ng-select.ng-select-opened>.ng-select-container {
		background: transparent !important;
	}

	.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
		background-color: #202124 !important;
		color: #ffff;
		font-size: 13px;
		font-weight: none;

	}

	.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
		color: #ffff !important;
	}

	.ng-dropdown-panel.ng-select-bottom {
		border: 1px solid #666666 !important;
	}

	.smart-form .checkbox input:checked+i {
		border: 2px solid #666666;
		border-radius: 5px;
	}

	.fuelux .wizard ul li .chevron {
		border-left: 14px solid #3B424C;
	}

	.fuelux .wizard ul li.complete:hover .chevron:before {
		border-left: 14px solid #171717;
	}
	.smart-form .checkbox input:checked+i {
		border: 2px solid #666666;
		border-radius: 5px;
	}

}

/*
---------------------------------------------------------------------------------------
    End Dark theme style
---------------------------------------------------------------------------------------
*/

/*
---------------------------------------------------------------------------------------
    Default White theme style
---------------------------------------------------------------------------------------
*/
.smart-style-2 {
	#logo {
		//background-image: url(/assets/img/svgImages/logoWhite.svg);
		margin-top: 10px;
	}

	.login-info {
		color: #212529;
	}

	// #logo-group {
	// 	background:$common_base_color !important;
	// }
	aside {
		// background: $common_base_color !important;
		color: #fff !important;
		// background: #171819 !important;
		// background-image: linear-gradient(#666666, #666666) !important;
		background: #ededed !important;
	}

	.alignscndRowcntntBorder {

		border-radius: 8px;
		border: 1px solid #dadce0;
		overflow: hidden;
	}

	nav ul .active>a {
		// color: #202427 !important;
		font-weight: 400;
	}

	.setnavBackgroundImg {
		background-image: url(/assets/img/svgImages/Group349.svg);
	}

	.ng-select .ng-select-container {
		background: transparent !important;
		border-radius: 10px !important;
		border: none;
	}

	.ng-select.ng-select-opened>.ng-select-container {
		background: transparent !important;
	}

	.companySelect .ng-select-container .ng-value-container .ng-value {
		font-size: 13px;
	}

	.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
		color: #0C1214 !important;
		background-color: #fff;
	}

	.smart-form .checkbox input:checked+i {
		border-color: #666666 !important;
	}

	.fuelux .wizard ul li .chevron {
		border-left: 14px solid #d4d4d4;
	}
	.smart-form .checkbox input:checked+i {
		border-color: #666666 !important;
	          }
}

/*
---------------------------------------------------------------------------------------
    White  theme style
---------------------------------------------------------------------------------------
*/
:root {
    --theme-color-1: #8B9635;
    --header-color:rgba(183, 235, 0, 0.4);
    --logo-color:transparent;
    --hovertext-color: #ffffff;
    --text-color: #111;
    --info-color: #8B9635;
    --filter: brightness(0) saturate(100%)
}

$primary-color: #fff;
$tablehover-color:#073E84;
$secondaryFocus:#0C1214;
$fontColor:#959595;
$invalid-bordercolor:#dc3545;
$base-color:#E2E2E2;
$subtext-color:#7A7A7A;
$tabsborder-color:#616161;
$table-bgcolor:#1E1E1E;
$widget-color: #171717;
$setblackFont-bgcolor:#FFFFFF;
$border-color:#3B424C;
$tabsborder-color:#707070;
$fontbackground-color:#739E73;
$buttoncolor:#00B894;
$datepicker-bgcolor:#F5F5F5;
$header-bgcolor:#333333;
$header-border:#262626;
$buttondanger-color:#dc3545;
$table-backgroundcolor:#393939;
$footer-color:#8B91A0;
$content-bg-color:#202124;
$panel-color:#8B91A0;
$common_base_color:var(--theme-color-1);
$darkTextColor: #111;
$fontcommon-color:#000;



$subscribe-bgcolor:#073E84;
$search-border:#3B424C;
$common-color:#000;
$card-bordercolor:#3B424C;
$payment-color:#005397;
$formcolor:#7991b4;
$commonbase-color:var(--theme-color-1);
$tabsborder:#ddd;
$secondary-color:#202528;
$card-color:#959595;
$widget-bgcolor:#171717;
$activetab-color:#707070;
$invalid-color:#eb0042 ;
$light-border:#cccccc;
$whiteborder-color: #ccc;
$header-color:var(--header-color);
$logo_color:var(--logo-color);
$hovertext-color:var(--hovertext-color);
$text-color: var(--text-color);
$info-color: var(--info-color);
$filter: var(--filter);